<template>
  <b-card>
    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(save(true))"
        @reset.prevent="resetForm"
      >
        <b-row
          align-h="between"
        >
          <b-col
            cols="8"
            lg="8"
            md="8"
          >
            <b-row>
              <b-col
                cols="12"
                lg="12"
                md="12"
              >
                <!-- Title -->
                <validation-provider
                  #default="validationContext"
                  name="Titulo"
                  rules="required"
                >
                  <label>Titulo</label>
                  <b-form-input
                    v-if="plantillaData"
                    v-model="plantillaData.titulo"
                    placeholder="Titulo"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>

              <b-col
                cols="8"
                lg="8"
                md="8"
                class="mt-1"
              >

                <!-- Type of Plantilla -->
                <label>Tipo de Plantilla</label>
                <b-row
                  align-h="between"
                  class="px-1"
                >
                  <b-form-radio
                    v-if="plantillaData"
                    v-model="plantillaData.type"
                    name="some-radios"
                    value="CABECERA"
                  >Cabecera</b-form-radio>
                  <b-form-radio
                    v-if="plantillaData"
                    v-model="plantillaData.type"
                    name="some-radios"
                    value="CONTENIDO"
                  >Contenido</b-form-radio>
                  <b-form-radio
                    v-if="plantillaData"
                    v-model="plantillaData.type"
                    name="some-radios"
                    value="PIE"
                  >Pie</b-form-radio>
                </b-row>
              </b-col>
              <b-col
                cols="4"
                lg="4"
                md="4"
                class="d-flex justify-content-end"
              >
                <!-- Estado -->
                <b-form-checkbox
                  v-if="plantillaData"
                  v-model="plantillaData.estado"
                  name="check-button"
                  switch
                  class="mt-2"
                >
                  <strong>Activo</strong>
                </b-form-checkbox>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            cols="4"
            lg="4"
            md="4"
          >
            <b-row class="pt-1">
              <b-col
                cols="6"
                md="6"
                lg="6"
              >
                <b-button
                  style="width: 200px; margin-top: 11px"
                  class="mr-1"
                  variant="primary"
                  @click="printPreview()"
                >Vista Previa
                  <feather-icon icon="EyeIcon" />
                </b-button>
              </b-col>
              <b-col
                cols="6"
                md="6"
                lg="6"
              >
                <b-button
                  style="width: 200px; margin-top: 11px"
                  class="mr-1"
                  variant="primary"
                  @click="save(false)"
                >Guardar
                  <feather-icon icon="SaveIcon" />
                </b-button>
              </b-col>
              <b-col
                cols="12"
                md="12"
                lg="12"
                class="d-flex justify-content-end mr-1"
              >
                <b-button
                  style="width: 200px; margin-top: 11px;margin-right: 26px;"
                  variant="primary"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  type="submit"
                >Guardar y salir
                  <feather-icon icon="ExitIcon" />
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <!-- Añade campos -->
        <b-row
          class="mt-3 mb-1 sticky-toolbar-1"
          align-v="center"
          align-h="between"
        >
          <b-col>
            <h4>Diseño de Plantilla</h4>
          </b-col>
          <!-- Key Attributes -->
          <b-col>
            <b-row align-h="end">
              <b-col
                cols="12"
                lg="8"
                md="8"
              >
                <v-select
                  v-model="key"
                  :options="avaiableKeys"
                  :clearable="false"
                  placeholder="Campos"
                />
              </b-col>
              <b-col>
                <b-button
                  block
                  variant="outline-primary"
                  @click="addKeyToCursorPosition()"
                >Añadir</b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <!-- Ckeditor -->
        <div class="ckeditor-toolbar sticky-toolbar-2" />
        <div class="ckeditor-container">
          <ckeditor
            v-model="plantillaData.body_html"
            :editor="editorType"
            :config="editorConfig"
            @ready="onEditorReady"
          />
        </div>

        <!-- Save/Cancel buttons -->
        <b-row
          class="mt-2"
          al
        >
          <b-col>
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              type="submit"
            >
              Guardar cambios
            </b-button>
            <b-button
              variant="outline-secondary"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              :to="{ name: 'plantillas-list' }"
            >
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
// Form validators
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'

import UploadAdapter from '@/middlewares/CkCustomUploadAdapter'
import vSelect from 'vue-select'
import { ref, onUnmounted } from '@vue/composition-api'
import plantillaStoreModule from '@/views/diseno/plantillas/plantillasStoreModule'
import store from '@/store'
import router from '@/router'
import ckConfig from '@/middlewares/ckConfig'
import { avaiableKeys } from '@/views/diseno/plantillas/helper'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import CustomEditor from 'ckeditor5-custom-build/build/ckeditor'

import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormRadio,
  BButton,
  BFormCheckbox,
  BForm,
  BFormInvalidFeedback,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormRadio,
    BButton,
    BFormCheckbox,
    BForm,
    BFormInvalidFeedback,

    ckeditor: CKEditor.component,

    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      editor: null,
      key: '',
      avaiableKeys,
      editorType: CustomEditor,
      editorConfig: {
        extraPlugins: [this.uploader],
        fontColor: ckConfig.fontColor,
        fontBackgroundColor: ckConfig.fontBackgroundColor,
        fontFamily: ckConfig.fontFamily,
      },
    }
  },
  watch: {
    plantillaData: {
      handler(value) {
        if (this.editor.getData() !== value.body_html) {
          this.editor.setData(value.body_html ?? '')
        }
      },
      deep: true,
    },
  },
  methods: {
    uploader(editor) {
      /* eslint-disable-next-line no-param-reassign */
      editor.plugins.get('FileRepository').createUploadAdapter = loader => new UploadAdapter(loader)
    },
    save(exit) {
      this.plantillaData.body_html = this.editor.getData()
      const campos = []
      this.avaiableKeys.forEach(element => {
        if (this.plantillaData.body_html.includes(element)) {
          campos.push(element)
        }
      })

      this.plantillaData.campos = campos.toString()
      this.$store
        .dispatch('app-plantilla/addPlantilla', this.plantillaData)
        .then(res => {
          // eslint-disable-next-line no-restricted-globals
          if (router.currentRoute.params.id === 'new') location.assign(`/plantillas/edit/${res.data.id}`)
          if (exit) this.$router.push({ name: 'plantillas-list' })
        })
    },
    onEditorReady(editor) {
      this.editor = editor

      // Set a custom container for the toolbar.
      document.querySelector('.ckeditor-toolbar').appendChild(editor.ui.view.toolbar.element)
      document.querySelector('.ck-toolbar').classList.add('ck-reset_all')
    },
    printPreview() {
      store
        .dispatch('app-plantilla/printPreview', this.plantillaData.id)
        .then(response => {
          window.open(response.ruta, '_blank')
        })
    },
    addKeyToCursorPosition() {
      this.editor.model.change(writer => {
        const insertPosition = this.editor.model.document.selection.getFirstPosition()
        writer.insertText(this.key, insertPosition)
      })
    },
  },

  setup() {
    const PLANTILLA_APP_STORE_MODULE_NAME = 'app-plantilla'
    const plantillaData = ref(null)
    const blankPlantillaData = {
      titulo: '',
      type: 'CONTENIDO',
      body_html: '<p>Contenido</p>',
      estado: false,
      campos: '',
    }

    // Register module
    if (!store.hasModule(PLANTILLA_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        PLANTILLA_APP_STORE_MODULE_NAME,
        plantillaStoreModule,
      )
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PLANTILLA_APP_STORE_MODULE_NAME)) store.unregisterModule(PLANTILLA_APP_STORE_MODULE_NAME)
    })

    if (router.currentRoute.params.id !== 'new') {
      store
        .dispatch('app-plantilla/fetchPlantilla', router.currentRoute.params.id)
        .then(response => {
          plantillaData.value = response
        })
        .catch(error => {
          if (error.response.status === 404) {
            plantillaData.value = undefined
          }
        })
    } else {
      plantillaData.value = blankPlantillaData
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(blankPlantillaData)

    return {
      plantillaData,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style>
.sticky-toolbar-1 {
  position: sticky;
  z-index: 6;
  top: 81px;
  display: inline-block;
  background-color: #fff;
  padding-bottom: 15px;
  width: 100%;
}
.sticky-toolbar-2 {
  position: sticky;
  z-index: 5;
  top: 160px;
  display: inline-block;
  width: 100%;
}
.ck-content {
  border: 1px solid #f2f2f2;
}
.ck-editor__editable {
   padding: 5rem 0;
   max-height: 700px;
   overflow-y: auto;
 }
.ck-editor__editable {
  width: 21.0529166667cm;
  min-height: 29.7529166667cm;
  margin: 0 auto;
  padding: 2cm 1.2cm;
  border: 1px #d3d3d3 solid;
  background-color: #fff;
  -webkit-box-shadow: 0 0 5px rgba(0,0,0,.1);
  box-shadow: 0 0 5px rgba(0,0,0,.1);
  overflow-wrap: anywhere;
  word-break: break-word;
}
.ckeditor-container {
  background-color: #f2f2f2;
  padding: 40px;
  box-shadow: inset 0 0 4px;
}
</style>
