export const avaiableKeys = [
  '{{fecha_hoy}}',
  '{{horario}}',
  '{{matricula}}',
  '{{nombre}}',
  '{{apellidos}}',
  '{{fin_contrato}}',
  '{{inicio_contrato}}',
  '{{tipo_contrato}}',
  '{{tarjeta_tickaje}}',
  '{{vehiculo}}',
  '{{vehiculo_matricula}}',
  '{{dni}}',
  '{{departamento}}',
  '{{direccion}}',
  '{{poblacion}}',
  '{{nie}}',
  '{{email}}',
  '{{carnet}}',
  '{{telefono}}',
  '{{movil}}',
  '{{reconocimiento_medico}}',
  '{{hora_revision}}',
]
