export default {
  fontColor: {
    colors: [
      {
        color: 'black',
        label: 'Black',
      },
      {
        color: 'grey',
        label: 'Grey',
      },
      {
        color: 'lightgrey',
        label: 'Light Grey',
      },
      {
        color: 'white',
        label: 'White',
      },
      {
        color: 'red',
        label: 'Red',
      },
      {
        color: 'blue',
        label: 'Blue',
      },
      {
        color: 'green',
        label: 'Green',
      },
      {
        color: 'orange',
        label: 'Orange',
      },
      {
        color: 'purple',
        label: 'Purple',
      },
      {
        color: 'brown',
        label: 'Brown',
      },
    ],
  },
  fontBackgroundColor: {
    colors: [
      {
        color: 'black',
        label: 'Black',
      },
      {
        color: 'grey',
        label: 'Grey',
      },
      {
        color: 'lightgrey',
        label: 'Light Grey',
      },
      {
        color: 'white',
        label: 'White',
      },
      {
        color: 'red',
        label: 'Red',
      },
      {
        color: 'blue',
        label: 'Blue',
      },
      {
        color: 'green',
        label: 'Green',
      },
      {
        color: 'orange',
        label: 'Orange',
      },
      {
        color: 'purple',
        label: 'Purple',
      },
      {
        color: 'brown',
        label: 'Brown',
      },
    ],
  },
  fontFamily: {
    options: [
      'Montserrat, Arial, sans-serif',
      'Open Sans, Arial, sans-serif',
      'Ubuntu Mono, Courier New, Courier, monospace'
    ],
  },
}
